import { createStore } from "vuex";
import { apiAlarm } from "@/api/user";
import { apiGameLiveInfo, apiNextInfo, apiGamePickster } from "@/api/game";
import { apiGamePick } from "@/api/pick";
import { apiCelebList, apiFanList, apiUserBlockingList } from "@/api/user";
import { apiRoomImmigration, apiRoomList, apiRoomMine } from "@/api/chat";
import io from "socket.io-client";
import { SOCKET_INFO } from "@/libs/constants";

export default createStore({
  state: {
    dhBlock: false,
    domainURL: process.env.VUE_APP_WEB_URI,
    adminURL: "https://admin.acbpdev.work",
    soundActive: true,
    talkRoomData: {},
    pickAlarmData: [],
    signUpCount: 0,
    userInfo: {
      active: 0,
      bannerImgSrc: "",
      cash: 0,
      exp: 0,
      familyIdx: 0,
      familyNm: "",
      hanmadi: "",
      levelCd: "",
      lgnId: "",
      lgnPw: null,
      mail: "",
      nickName: "",
      point: 0,
      profileImgSrc: "",
      regDt: "",
      udtDt: "",
      userIdx: 0,
    },
    checkRoomInfo: {},
    myRoom: 0,
    myEOSpowerball5Pick: 0,
    myEOSpowerball3Pick: 0,
    myCoinpowerball5Pick: 0,
    myCoinpowerball3Pick: 0,
    myMetapowerball5Pick: 0,
    myMetapowerball3Pick: 0,
    myCoinladder5Pick: 0,
    myCoinladder3Pick: 0,
    myMetaladder5Pick: 0,
    myMetaladder3Pick: 0,
    myDhpowerballPick: 0,
    myDhladderPick: 0,
    myPbgpowerballPick: 0,
    nextEOSpowerball5Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextEOSpowerball3Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextCoinpowerball5Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextCoinpowerball3Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextMetapowerball5Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextMetapowerball3Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextCoinladder5Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextCoinladder3Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextMetaladder5Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextMetaladder3Info: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextDhpowerballInfo: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextDhladderInfo: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    nextPbgpowerballInfo: {
      time: "",
      gameRound: 0,
      diff: 0,
      diffTime: "",
    },
    diffTime: 0,
    EOSpowerball5diffTime: 0,
    EOSpowerball3diffTime: 0,
    coinpowerball5diffTime: 0,
    coinpowerball3diffTime: 0,
    metapowerball5diffTime: 0,
    metapowerball3diffTime: 0,
    coinladder5diffTime: 0,
    coinladder3diffTime: 0,
    metaladder5diffTime: 0,
    metaladder3diffTime: 0,
    dhpowerballdiffTime: 0,
    dhladderdiffTime: 0,
    pbgpowerballdiffTime: 0,
    loggedIn: false,
    currentPage: "",
    alarm: {
      C: 0,
      S: 0,
      Z: 0,
      F: 0,
      TOTAL: 0,
    },
    powerballRound: 0,
    EOSpowerball5Round: 0,
    EOSpowerball3Round: 0,
    coinpowerball5Round: 0,
    coinpowerball3Round: 0,
    metapowerball5Round: 0,
    metapowerball3Round: 0,
    coinladder5Round: 0,
    coinladder3Round: 0,
    metaladder5Round: 0,
    metaladder3Round: 0,
    dhpowerballRound: 0,
    dhladderRound: 0,
    pbgpowerballRound: 0,
    giftUserInfo: {},
    roomInfo: "",
    myEOSpowerball5PickInfo: {
      oe: 0,
      pwOe: 0,
      pwUnover: 0,
      size: 0,
      unover: 0,
    },
    myEOSpowerball3PickInfo: {
      oe: 0,
      pwOe: 0,
      pwUnover: 0,
      size: 0,
      unover: 0,
    },
    myCoinpowerball5PickInfo: {
      oe: 0,
      pwOe: 0,
      pwUnover: 0,
      size: 0,
      unover: 0,
    },
    myCoinpowerball3PickInfo: {
      oe: 0,
      pwOe: 0,
      pwUnover: 0,
      size: 0,
      unover: 0,
    },
    myMetapowerball5PickInfo: {
      oe: 0,
      pwOe: 0,
      pwUnover: 0,
      size: 0,
      unover: 0,
    },
    myMetapowerball3PickInfo: {
      oe: 0,
      pwOe: 0,
      pwUnover: 0,
      size: 0,
      unover: 0,
    },
    myCoinladder5PickInfo: {
      sub: 0,
      lr: 0,
      oe: 0,
      line: 0,
    },
    myCoinladder3PickInfo: {
      sub: 0,
      lr: 0,
      oe: 0,
      line: 0,
    },
    myMetaladder5PickInfo: {
      sub: 0,
      lr: 0,
      oe: 0,
      line: 0,
    },
    myMetaladder3PickInfo: {
      sub: 0,
      lr: 0,
      oe: 0,
      line: 0,
    },

    myDhpowerballPickInfo: {
      oe: 0,
      pwOe: 0,
      pwUnover: 0,
      size: 0,
      unover: 0,
    },
    myDhladderPickInfo: {
      oe: 0,
      sub1: 0,
      sub2: 0,
      lr1: 0,
      lr2: 0,
      oe1: 0,
      oe2: 0,
      line1: 0,
      line2: 0,
    },
    myPbgpowerballPickInfo: {
      oe: 0,
      pwOe: 0,
      pwUnover: 0,
      size: 0,
      unover: 0,
    },
    gamePickRatio: {
      coinpowerball3: {
        oe1: 0,
        oe2: 0,
        pwOe1: 0,
        pwOe2: 0,
        pwUnover1: 0,
        pwUnover2: 0,
        size1: 0,
        size2: 0,
        size3: 0,
        unover1: 0,
        unover2: 0,
      },
      coinpowerball5: {
        oe1: 0,
        oe2: 0,
        pwOe1: 0,
        pwOe2: 0,
        pwUnover1: 0,
        pwUnover2: 0,
        size1: 0,
        size2: 0,
        size3: 0,
        unover1: 0,
        unover2: 0,
      },
      coinladder3: {
        sub1: 0,
        sub2: 0,
        sub3: 0,
        sub4: 0,
        lr1: 0,
        lr2: 0,
        oe1: 0,
        oe2: 0,
        line1: 0,
        line2: 0,
      },
      coinladder5: {
        sub1: 0,
        sub2: 0,
        sub3: 0,
        sub4: 0,
        lr1: 0,
        lr2: 0,
        oe1: 0,
        oe2: 0,
        line1: 0,
        line2: 0,
      },
      dhpowerball: {
        oe1: 0,
        oe2: 0,
        pwOe1: 0,
        pwOe2: 0,
        pwUnover1: 0,
        pwUnover2: 0,
        size1: 0,
        size2: 0,
        size3: 0,
        unover1: 0,
        unover2: 0,
      },
      dhladder: {
        sub1: 0,
        sub2: 0,
        sub3: 0,
        sub4: 0,
        lr1: 0,
        lr2: 0,
        oe1: 0,
        oe2: 0,
        line1: 0,
        line2: 0,
      },
      eospowerball3: {
        oe1: 0,
        oe2: 0,
        pwOe1: 0,
        pwOe2: 0,
        pwUnover1: 0,
        pwUnover2: 0,
        size1: 0,
        size2: 0,
        size3: 0,
        unover1: 0,
        unover2: 0,
      },
      eospowerball5: {
        oe1: 0,
        oe2: 0,
        pwOe1: 0,
        pwOe2: 0,
        pwUnover1: 0,
        pwUnover2: 0,
        size1: 0,
        size2: 0,
        size3: 0,
        unover1: 0,
        unover2: 0,
      },
      metapowerball5: {
        oe1: 0,
        oe2: 0,
        pwOe1: 0,
        pwOe2: 0,
        pwUnover1: 0,
        pwUnover2: 0,
        size1: 0,
        size2: 0,
        size3: 0,
        unover1: 0,
        unover2: 0,
      },
      metapowerball3: {
        oe1: 0,
        oe2: 0,
        pwOe1: 0,
        pwOe2: 0,
        pwUnover1: 0,
        pwUnover2: 0,
        size1: 0,
        size2: 0,
        size3: 0,
        unover1: 0,
        unover2: 0,
      },
      metaladder5: {
        sub1: 0,
        sub2: 0,
        sub3: 0,
        sub4: 0,
        lr1: 0,
        lr2: 0,
        oe1: 0,
        oe2: 0,
        line1: 0,
        line2: 0,
      },
      metaladder3: {
        sub1: 0,
        sub2: 0,
        sub3: 0,
        sub4: 0,
        lr1: 0,
        lr2: 0,
        oe1: 0,
        oe2: 0,
        line1: 0,
        line2: 0,
      },
      pbgpowerball: {
        oe1: 0,
        oe2: 0,
        pwOe1: 0,
        pwOe2: 0,
        pwUnover1: 0,
        pwUnover2: 0,
        size1: 0,
        size2: 0,
        size3: 0,
        unover1: 0,
        unover2: 0,
      },
    },
    liveList: [],
    EOSpowerball5LiveList: [],
    EOSpowerball3LiveList: [],
    coinpowerball5LiveList: [],
    coinpowerball3LiveList: [],
    metapowerball5LiveList: [],
    metapowerball3LiveList: [],
    coinladder5LiveList: [],
    coinladder3LiveList: [],
    metaladder5LiveList: [],
    metaladder3LiveList: [],
    dhpowerballLiveList: [],
    dhladderLiveList: [],
    pbgpowerballLiveList: [],
    roomList: [],
    fanList: [],
    celebList: [],
    blockList: [],
    EOSpowerball5TimeOver: true,
    EOSpowerball3TimeOver: true,
    coinpowerball5TimeOver: true,
    coinpowerball3TimeOver: true,
    metapowerball5TimeOver: true,
    metapowerball3TimeOver: true,
    coinladder5TimeOver: true,
    coinladder3TimeOver: true,
    metaladder5TimeOver: true,
    metaladder3TimeOver: true,
    dhpowerballTimeOver: true,
    dhladderTimeOver: true,
    pbgpowerballTimeOver: true,
    nowPowerballPickUser: [],
    nowEOSpowerball5PickUser: [],
    nowEOSpowerball3PickUser: [],
    nowCoinpowerball5PickUser: [],
    nowCoinpowerball3PickUser: [],
    nowMetapowerball5PickUser: [],
    nowMetapowerball3PickUser: [],
    nowCoinladder5PickUser: [],
    nowCoinladder3PickUser: [],
    nowMetaladder5PickUser: [],
    nowMetaladder3PickUser: [],
    nowDhpowerballPickUser: [],
    nowPbgpowerballPickUser: [],
    powerballPicksterList: [],
    EOSpowerball5PicksterList: [],
    EOSpowerball3PicksterList: [],
    coinpowerball5PicksterList: [],
    coinpowerball3PicksterList: [],
    metapowerball5PicksterList: [],
    metapowerball3PicksterList: [],
    coinladder5PicksterList: [],
    coinladder3PicksterList: [],
    metaladder5PicksterList: [],
    metaladder3PicksterList: [],
    dhpowerballPicksterList: [],
    dhladderPicksterList: [],
    pbgpowerballPicksterList: [],
  },
  getters: {
    dhBlock(state) {
      return state.dhBlock;
    },
    soundActive(state) {
      return state.soundActive;
    },
    pickAlarmData(state) {
      return state.pickAlarmData;
    },
    checkRoomInfo(state) {
      return state.checkRoomInfo;
    },
    myRoom(state) {
      return state.myRoom;
    },
    roomList(state) {
      return state.roomList;
    },
    gamePickRatio(state) {
      return state.gamePickRatio;
    },
    EOSpowerball5TimeOver(state) {
      return state.EOSpowerball5TimeOver;
    },
    EOSpowerball3TimeOver(state) {
      return state.EOSpowerball3TimeOver;
    },
    coinpowerball5TimeOver(state) {
      return state.coinpowerball5TimeOver;
    },
    coinpowerball3TimeOver(state) {
      return state.coinpowerball3TimeOver;
    },
    metapowerball5TimeOver(state) {
      return state.metapowerball5TimeOver;
    },
    metapowerball3TimeOver(state) {
      return state.metapowerball3TimeOver;
    },
    coinladder5TimeOver(state) {
      return state.coinladder5TimeOver;
    },
    coinladder3TimeOver(state) {
      return state.coinladder3TimeOver;
    },
    metaladder5TimeOver(state) {
      return state.metaladder5TimeOver;
    },
    metaladder3TimeOver(state) {
      return state.metaladder3TimeOver;
    },
    dhpowerballTimeOver(state) {
      return state.dhpowerballTimeOver;
    },
    dhladderTimeOver(state) {
      return state.dhladderTimeOver;
    },
    pbgpowerballTimeOver(state) {
      return state.pbgpowerballTimeOver;
    },
    giftUserInfo(state) {
      return state.giftUserInfo;
    },
    roomInfo(state) {
      return state.roomInfo;
    },
    userInfo(state) {
      return state.userInfo;
    },
    loggedIn(state) {
      return state.loggedIn;
    },
    currentPage(state) {
      return state.currentPage;
    },
    nextEOSpowerball5Info(state) {
      return state.nextEOSpowerball5Info;
    },
    nextEOSpowerball3Info(state) {
      return state.nextEOSpowerball3Info;
    },
    nextCoinpowerball5Info(state) {
      return state.nextCoinpowerball5Info;
    },
    nextCoinpowerball3Info(state) {
      return state.nextCoinpowerball3Info;
    },
    nextMetapowerball5Info(state) {
      return state.nextMetapowerball5Info;
    },
    nextMetapowerball3Info(state) {
      return state.nextMetapowerball3Info;
    },
    nextCoinladder5Info(state) {
      return state.nextCoinladder5Info;
    },
    nextCoinladder3Info(state) {
      return state.nextCoinladder3Info;
    },
    nextMetaladder5Info(state) {
      return state.nextMetaladder5Info;
    },
    nextMetaladder3Info(state) {
      return state.nextMetaladder3Info;
    },
    nextDhpowerballInfo(state) {
      return state.nextDhpowerballInfo;
    },
    nextDhladderInfo(state) {
      return state.nextDhladderInfo;
    },
    nextPbgpowerballInfo(state) {
      return state.nextPbgpowerballInfo;
    },
    diffTime(state) {
      return state.diffTime;
    },
    EOSpowerball5diffTime(state) {
      return state.EOSpowerball5diffTime;
    },
    EOSpowerball3diffTime(state) {
      return state.EOSpowerball3diffTime;
    },
    coinpowerball5diffTime(state) {
      return state.coinpowerball5diffTime;
    },
    coinpowerball3diffTime(state) {
      return state.coinpowerball3diffTime;
    },
    metapowerball5diffTime(state) {
      return state.metapowerball5diffTime;
    },
    metapowerball3diffTime(state) {
      return state.metapowerball3diffTime;
    },
    coinladder5diffTime(state) {
      return state.coinladder5diffTime;
    },
    coinladder3diffTime(state) {
      return state.coinladder3diffTime;
    },
    metaladder5diffTime(state) {
      return state.metaladder5diffTime;
    },
    metaladder3diffTime(state) {
      return state.metaladder3diffTime;
    },
    dhpowerballdiffTime(state) {
      return state.dhpowerballdiffTime;
    },
    dhladderdiffTime(state) {
      return state.dhladderdiffTime;
    },
    pbgpowerballdiffTime(state) {
      return state.pbgpowerballdiffTime;
    },
    alarm(state) {
      return state.alarm;
    },
    liveList(state) {
      return state.liveList;
    },
    EOSpowerball5LiveList(state) {
      return state.EOSpowerball5LiveList;
    },
    EOSpowerball3LiveList(state) {
      return state.EOSpowerball3LiveList;
    },
    coinpowerball5LiveList(state) {
      return state.coinpowerball5LiveList;
    },
    coinpowerball3LiveList(state) {
      return state.coinpowerball3LiveList;
    },
    metapowerball5LiveList(state) {
      return state.metapowerball5LiveList;
    },
    metapowerball3LiveList(state) {
      return state.metapowerball3LiveList;
    },
    coinladder5LiveList(state) {
      return state.coinladder5LiveList;
    },
    coinladder3LiveList(state) {
      return state.coinladder3LiveList;
    },
    metaladder5LiveList(state) {
      return state.metaladder5LiveList;
    },
    metaladder3LiveList(state) {
      return state.metaladder3LiveList;
    },
    dhpowerballLiveList(state) {
      return state.dhpowerballLiveList;
    },
    dhladderLiveList(state) {
      return state.dhladderLiveList;
    },
    pbgpowerballLiveList(state) {
      return state.pbgpowerballLiveList;
    },
    fanList(state) {
      return state.fanList;
    },
    celebList(state) {
      return state.celebList;
    },
    blockList(state) {
      return state.blockList;
    },

    powerballRound(state) {
      return state.powerballRound;
    },
    EOSpowerball5Round(state) {
      return state.EOSpowerball5Round;
    },
    EOSpowerball3Round(state) {
      return state.EOSpowerball3Round;
    },
    coinpowerball5Round(state) {
      return state.coinpowerball5Round;
    },
    coinpowerball3Round(state) {
      return state.coinpowerball3Round;
    },
    metapowerball5Round(state) {
      return state.metapowerball5Round;
    },
    metapowerball3Round(state) {
      return state.metapowerball3Round;
    },
    coinladder5Round(state) {
      return state.coinladder5Round;
    },
    coinladder3Round(state) {
      return state.coinladder3Round;
    },
    metaladder5Round(state) {
      return state.metaladder5Round;
    },
    metaladder3Round(state) {
      return state.metaladder3Round;
    },
    dhpowerballRound(state) {
      return state.dhpowerballRound;
    },
    dhladderRound(state) {
      return state.dhladderRound;
    },
    pbgpowerballRound(state) {
      return state.pbgpowerballRound;
    },
    nowPowerballPickUser(state) {
      return state.nowPowerballPickUser;
    },
    nowEOSpowerball5PickUser(state) {
      return state.nowEOSpowerball5PickUser;
    },
    nowEOSpowerball3PickUser(state) {
      return state.nowEOSpowerball3PickUser;
    },
    nowCoinball5PickUser(state) {
      return state.nowCoinpowerball5PickUser;
    },
    nowCoinball3PickUser(state) {
      return state.nowCoinpowerball3PickUser;
    },
    nowMetapowerball5PickUser(state) {
      return state.nowMetapowerball5PickUser;
    },
    nowMetapowerball3PickUser(state) {
      return state.nowMetapowerball3PickUser;
    },
    nowCoinladder5PickUser(state) {
      return state.nowCoinladder5PickUser;
    },
    nowCoinladder3PickUser(state) {
      return state.nowCoinladder3PickUser;
    },
    nowMetaladder5PickUser(state) {
      return state.nowMetaladder5PickUser;
    },
    nowMetaladder3PickUser(state) {
      return state.nowMetaladder3PickUser;
    },
    nowDhpowerballPickUser(state) {
      return state.nowMetaladder5PickUser;
    },
    nowPbgpowerballPickUser(state) {
      return state.nowMetaladder3PickUser;
    },
    signUpCount(state) {
      return state.signUpCount;
    },
    powerballPicksterList(state) {
      return state.powerballPicksterList;
    },
    EOSpowerball5PicksterList(state) {
      return state.EOSpowerball5PicksterList;
    },
    EOSpowerball3PicksterList(state) {
      return state.EOSpowerball3PicksterList;
    },
    coinpowerball5PicksterList(state) {
      return state.coinpowerball5PicksterList;
    },
    coinpowerball3PicksterList(state) {
      return state.coinpowerball3PicksterList;
    },
    metapowerball5PicksterList(state) {
      return state.metapowerball5PicksterList;
    },
    metapowerball3PicksterList(state) {
      return state.metapowerball3PicksterList;
    },
    coinladder5PicksterList(state) {
      return state.coinladder5PicksterList;
    },
    coinladder3PicksterList(state) {
      return state.coinladder3PicksterList;
    },
    metaladder5PicksterList(state) {
      return state.metaladder5PicksterList;
    },
    metaladder3PicksterList(state) {
      return state.metaladder3PicksterList;
    },
    dhpowerballPicksterList(state) {
      return state.dhpowerballPicksterList;
    },
    dhladderPicksterList(state) {
      return state.dhladderPicksterList;
    },
    pbgpowerballPicksterList(state) {
      return state.pbgpowerballPicksterList;
    },
    myEOSpowerball5Pick(state) {
      return state.myEOSpowerball5Pick;
    },
    myEOSpowerball3Pick(state) {
      return state.myEOSpowerball3Pick;
    },
    myCoinpowerball5Pick(state) {
      return state.myCoinpowerball5Pick;
    },
    myCoinpowerball3Pick(state) {
      return state.myCoinpowerball3Pick;
    },
    myMetapowerball5Pick(state) {
      return state.myMetapowerball5Pick;
    },
    myMetapowerball3Pick(state) {
      return state.myMetapowerball3Pick;
    },
    myCoinladder5Pick(state) {
      return state.myCoinladder5Pick;
    },
    myCoinladder3Pick(state) {
      return state.myCoinladder3Pick;
    },
    myMetaladder5Pick(state) {
      return state.myMetaladder5Pick;
    },
    myMetaladder3Pick(state) {
      return state.myMetaladder3Pick;
    },
    myDhpowerballPick(state) {
      return state.myDhpowerballPick;
    },
    myDhladderPick(state) {
      return state.myDhladderPick;
    },
    myPbgpowerballPick(state) {
      return state.myPbgpowerballPick;
    },
  },
  mutations: {
    setDhBlock(state, data) {
      state.dhBlock = data;
    },
    alarmSet(state) {
      state.soundActive = !state.soundActive;
      if (state.soundActive) {
        alert("픽 알람 및 대화 알람 소리가 활성화됩니다.");
      } else {
        alert("픽 알람 및 대화 알람 소리가 음소거됩니다.");
      }
    },
    setPickAlarmData(state, data) {
      state.pickAlarmData.push(data);
    },
    setRmAlarmData(state, data) {
      state.pickAlarmData.shift(data);
    },
    setPickDataSplice(state, index) {
      state.pickAlarmData.splice(index, 1);
    },
    setTalkRoomData(state, data) {
      state.talkRoomData = data;
    },

    setGamePickRatio(state, ratio) {
      state.gamePickRatio = ratio;
    },
    setCheckRoomInfo(state, info) {
      state.checkRoomInfo = info;
    },
    setMyRoom(state, room) {
      state.myRoom = room;
    },
    setmyEOSpowerball5Pick(state, pick) {
      state.myEOSpowerball5Pick = pick;
    },
    setmyEOSpowerball3Pick(state, pick) {
      state.myEOSpowerball3Pick = pick;
    },
    setmyCoinpowerball5Pick(state, pick) {
      state.myCoinpowerball5Pick = pick;
    },
    setmyCoinpowerball3Pick(state, pick) {
      state.myCoinpowerball3Pick = pick;
    },
    setmyMetapowerball5Pick(state, pick) {
      state.myMetapowerball5Pick = pick;
    },
    setmyMetapowerball3Pick(state, pick) {
      state.myMetapowerball3Pick = pick;
    },
    setmyCoinladder5Pick(state, pick) {
      state.myCoinladder5Pick = pick;
    },
    setmyCoinladder3Pick(state, pick) {
      state.myCoinladder3Pick = pick;
    },
    setmyMetaladder5Pick(state, pick) {
      state.myMetaladder5Pick = pick;
    },
    setmyMetaladder3Pick(state, pick) {
      state.myMetaladder3Pick = pick;
    },
    setmyDhpowerballPick(state, pick) {
      state.myDhpowerballPick = pick;
    },
    setmyDhladderPick(state, pick) {
      state.myDhladderPick = pick;
    },
    setmyPbgpowerballPick(state, pick) {
      state.myPbgpowerballPick = pick;
    },
    setmyEOSpowerball5PickInfo(state, pickInfo) {
      state.myEOSpowerball5PickInfo = pickInfo;
    },
    setmyEOSpowerball3PickInfo(state, pickInfo) {
      state.myEOSpowerball3PickInfo = pickInfo;
    },
    setmyCoinpowerball3PickInfo(state, pickInfo) {
      state.myCoinpowerball3PickInfo = pickInfo;
    },
    setmyCoinpowerball5PickInfo(state, pickInfo) {
      state.myCoinpowerball5PickInfo = pickInfo;
    },
    setmyMetapowerball5PickInfo(state, pickInfo) {
      state.myMetapowerball5PickInfo = pickInfo;
    },
    setmyMetapowerball3PickInfo(state, pickInfo) {
      state.myMetapowerball3PickInfo = pickInfo;
    },
    setmyCoinladder3PickInfo(state, pickInfo) {
      state.myCoinladder3PickInfo = pickInfo;
    },
    setmyCoinladder5PickInfo(state, pickInfo) {
      state.myCoinladder5PickInfo = pickInfo;
    },
    setmyMetaladder5PickInfo(state, pickInfo) {
      state.myMetaladder5PickInfo = pickInfo;
    },
    setmyMetaladder3PickInfo(state, pickInfo) {
      state.myMetaladder3PickInfo = pickInfo;
    },
    setmyDhpowerballPickInfo(state, pickInfo) {
      state.myDhpowerballPickInfo = pickInfo;
    },
    setmyDhladderPickInfo(state, pickInfo) {
      state.myDhladderPickInfo = pickInfo;
    },
    setmyPbgpowerballPickInfo(state, pickInfo) {
      state.myPbgpowerballPickInfo = pickInfo;
    },
    setRoomList(state, roomList) {
      state.roomList = roomList;
    },
    setLiveList(state, liveList) {
      state.liveList = liveList;
    },
    setEOSpowerball5LiveList(state, EOSpowerball5LiveList) {
      state.EOSpowerball5LiveList = EOSpowerball5LiveList;
    },
    setEOSpowerball3LiveList(state, EOSpowerball3LiveList) {
      state.EOSpowerball3LiveList = EOSpowerball3LiveList;
    },
    setCoinpowerball5LiveList(state, coinpowerball5LiveList) {
      state.coinpowerball5LiveList = coinpowerball5LiveList;
    },
    setCoinpowerball3LiveList(state, coinpowerball3LiveList) {
      state.coinpowerball3LiveList = coinpowerball3LiveList;
    },
    setMetapowerball5LiveList(state, metapowerball5LiveList) {
      state.metapowerball5LiveList = metapowerball5LiveList;
    },
    setMetapowerball3LiveList(state, metapowerball3LiveList) {
      state.metapowerball3LiveList = metapowerball3LiveList;
    },
    setCoinladder5LiveList(state, coinladder5LiveList) {
      state.coinladder5LiveList = coinladder5LiveList;
    },
    setCoinladder3LiveList(state, coinladder3LiveList) {
      state.coinladder3LiveList = coinladder3LiveList;
    },
    setMetaladder5LiveList(state, metaladder5LiveList) {
      state.metaladder5LiveList = metaladder5LiveList;
    },
    setMetaladder3LiveList(state, metaladder3LiveList) {
      state.metaladder3LiveList = metaladder3LiveList;
    },
    setDhpowerballLiveList(state, dhpowerballLiveList) {
      state.dhpowerballLiveList = dhpowerballLiveList;
    },
    setDhladderLiveList(state, dhladderLiveList) {
      state.dhladderLiveList = dhladderLiveList;
    },
    setPbgpowerballLiveList(state, pbgpowerballLiveList) {
      state.pbgpowerballLiveList = pbgpowerballLiveList;
    },
    setFanList(state, fanList) {
      state.fanList = fanList;
    },
    setCelebList(state, celebList) {
      state.celebList = celebList;
    },
    setBlockList(state, blockList) {
      state.blockList = blockList;
    },
    setPowerballRound(state, round) {
      state.powerballRound = round;
    },
    setEOSpowerball5Round(state, round) {
      state.EOSpowerball5Round = round;
    },
    setEOSpowerball3Round(state, round) {
      state.EOSpowerball3Round = round;
    },
    setcoinpowerball5Round(state, round) {
      state.coinpowerball5Round = round;
    },
    setcoinpowerball3Round(state, round) {
      state.coinpowerball3Round = round;
    },
    setmetapowerball5Round(state, round) {
      state.metapowerball5Round = round;
    },
    setmetapowerball3Round(state, round) {
      state.metapowerball3Round = round;
    },
    setcoinladder5Round(state, round) {
      state.coinladder5Round = round;
    },
    setcoinladder3Round(state, round) {
      state.coinladder3Round = round;
    },
    setmetaladder5Round(state, round) {
      state.metaladder5Round = round;
    },
    setmetaladder3Round(state, round) {
      state.metaladder3Round = round;
    },
    setdhpowerballRound(state, round) {
      state.dhpowerballRound = round;
    },
    setdhladderRound(state, round) {
      state.dhladderRound = round;
    },
    setpbgpowerballRound(state, round) {
      state.pbgpowerballRound = round;
    },
    setStoreUserInfo(state, userData) {
      state.userInfo = Object.assign(state.userInfo, userData);
    },
    setloginState(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setPageState(state, currentPage) {
      state.currentPage = currentPage;
    },
    setNextEOSpowerball5Info(state, info) {
      state.nextEOSpowerball5Info.time = info.time;
      state.nextEOSpowerball5Info.round = info.round;
    },
    setNextEOSpowerball3Info(state, info) {
      state.nextEOSpowerball3Info.time = info.time;
      state.nextEOSpowerball3Info.round = info.round;
    },
    setNextCoinpowerball5Info(state, info) {
      state.nextCoinpowerball5Info.time = info.time;
      state.nextCoinpowerball5Info.round = info.round;
    },
    setNextCoinpowerball3Info(state, info) {
      state.nextCoinpowerball3Info.time = info.time;
      state.nextCoinpowerball3Info.round = info.round;
    },
    setNextMetapowerball5Info(state, info) {
      state.nextMetapowerball5Info.time = info.time;
      state.nextMetapowerball5Info.round = info.round;
    },
    setNextMetapowerball3Info(state, info) {
      state.nextMetapowerball3Info.time = info.time;
      state.nextMetapowerball3Info.round = info.round;
    },
    setNextCoinladder5Info(state, info) {
      state.nextCoinladder5Info.time = info.time;
      state.nextCoinladder5Info.round = info.round;
    },
    setNextCoinladder3Info(state, info) {
      state.nextCoinladder3Info.time = info.time;
      state.nextCoinladder3Info.round = info.round;
    },
    setNextMetaladder5Info(state, info) {
      state.nextMetaladder5Info.time = info.time;
      state.nextMetaladder5Info.round = info.round;
    },
    setNextMetaladder3Info(state, info) {
      state.nextMetaladder3Info.time = info.time;
      state.nextMetaladder3Info.round = info.round;
    },
    setNextDhpowerballInfo(state, info) {
      state.nextDhpowerballInfo.time = info.time;
      state.nextDhpowerballInfo.round = info.round;
    },
    setNextDhladderInfo(state, info) {
      state.nextDhladderInfo.time = info.time;
      state.nextDhladderInfo.round = info.round;
    },
    setNextPbgpowerballInfo(state, info) {
      state.nextPbgpowerballInfo.time = info.time;
      state.nextPbgpowerballInfo.round = info.round;
    },
    updateDiff(state, diff) {
      state.diffTime = diff;
    },
    updateEOSpower5Diff(state, diff) {
      state.EOSpowerball5diffTime = diff;
    },
    updateEOSpower3Diff(state, diff) {
      state.EOSpowerball3diffTime = diff;
    },
    updatecoin5Diff(state, diff) {
      state.coinpowerball5diffTime = diff;
    },
    updatecoin3Diff(state, diff) {
      state.coinpowerball3diffTime = diff;
    },
    updatemeta5Diff(state, diff) {
      state.metapowerball5diffTime = diff;
    },
    updatemeta3Diff(state, diff) {
      state.metapowerball3diffTime = diff;
    },
    updatecoinladder5Diff(state, diff) {
      state.coinladder5diffTime = diff;
    },
    updatecoinladder3Diff(state, diff) {
      state.coinladder3diffTime = diff;
    },
    updatemetaladder5Diff(state, diff) {
      state.metaladder5diffTime = diff;
    },
    updatemetaladder3Diff(state, diff) {
      state.metaladder3diffTime = diff;
    },
    updatedhpowerballDiff(state, diff) {
      state.dhpowerballdiffTime = diff;
    },
    updatedhladderDiff(state, diff) {
      state.dhladderdiffTime = diff;
    },
    updatepbgpowerballDiff(state, diff) {
      state.pbgpowerballdiffTime = diff;
    },
    setAlarm(state, alarm) {
      const _old = state.alarm;
      const _new = alarm;
      let summary = 0;
      _old.C = 0;
      _old.S = 0;
      _old.F = 0;
      _old.Z = 0;
      for (const item of _new) {
        _old[item.kindCd] = item.count;
        if (item.kindCd !== "Z") {
          summary += item.count;
        }
      }
      _old.TOTAL = summary;
      state.alarm = _old;
    },
    setGiftUserInfo(state, userInfo) {
      state.giftUserInfo = userInfo;
    },
    setRoomInfo(state, roomInfo) {
      state.roomInfo = roomInfo;
    },
    updateEOSpower5TimeOver(state, stauts) {
      state.EOSpowerball5TimeOver = stauts;
    },
    updateEOSpower3TimeOver(state, stauts) {
      state.EOSpowerball3TimeOver = stauts;
    },
    updatecoin5TimeOver(state, stauts) {
      state.coinpowerball5TimeOver = stauts;
    },
    updatecoin3TimeOver(state, stauts) {
      state.coinpowerball3TimeOver = stauts;
    },
    updatemeta5TimeOver(state, stauts) {
      state.metapowerball5TimeOver = stauts;
    },
    updatemeta3TimeOver(state, stauts) {
      state.metapowerball3TimeOver = stauts;
    },
    updatecoinladder5TimeOver(state, stauts) {
      state.coinladder5TimeOver = stauts;
    },
    updatecoinladder3TimeOver(state, stauts) {
      state.coinladder3TimeOver = stauts;
    },
    updatemetaladder5TimeOver(state, stauts) {
      state.metaladder5TimeOver = stauts;
    },
    updatemetaladder3TimeOver(state, stauts) {
      state.metaladder3TimeOver = stauts;
    },
    updatedhpowerballTimeOver(state, stauts) {
      state.dhpowerballTimeOver = stauts;
    },
    updatedhladderTimeOver(state, stauts) {
      state.dhladderTimeOver = stauts;
    },
    updatepbgpowerballTimeOver(state, stauts) {
      state.pbgpowerballTimeOver = stauts;
    },
    setNowPowerballpickUser(state, list) {
      state.nowPowerballPickUser = list;
    },
    setNowEOSpowerball5PickUser(state, list) {
      state.nowEOSpowerball5PickUser = list;
    },
    setNowEOSpowerball3PickUser(state, list) {
      state.nowEOSpowerball3PickUser = list;
    },
    setNowCoinpowerball5lPickUser(state, list) {
      state.nowCoinpowerball5PickUser = list;
    },
    setNowCoinpowerball3lPickUser(state, list) {
      state.nowCoinpowerball3PickUser = list;
    },
    setNowMetapowerball5lPickUser(state, list) {
      state.nowMetapowerball5PickUser = list;
    },
    setNowMetapowerball3lPickUser(state, list) {
      state.nowMetapowerball3PickUser = list;
    },
    setNowCoinladder5lPickUser(state, list) {
      state.nowCoinladder5PickUser = list;
    },
    setNowCoinladder3lPickUser(state, list) {
      state.nowCoinladder3PickUser = list;
    },
    setNowMetaladder5lPickUser(state, list) {
      state.nowMetaladder5PickUser = list;
    },
    setNowMetaladder3lPickUser(state, list) {
      state.nowMetaladder3PickUser = list;
    },
    setNowDhpowerballlPickUser(state, list) {
      state.nowDhpowerballPickUser = list;
    },
    setNowPbgpowerballlPickUser(state, list) {
      state.nowPbgpowerballPickUser = list;
    },
    setSignUpCount(state, count) {
      state.signUpCount = state.signUpCount + count;
    },
    setPowerballPicksterList(state, list) {
      state.powerballPicksterList = list;
    },
    setEOSpowerball5PicksterList(state, list) {
      state.EOSpowerball5PicksterList = list;
    },
    setEOSpowerball3PicksterList(state, list) {
      state.EOSpowerball3PicksterList = list;
    },
    setCoinpowerball5PicksterList(state, list) {
      state.coinpowerball5PicksterList = list;
    },
    setCoinpowerball3PicksterList(state, list) {
      state.coinpowerball3PicksterList = list;
    },
    setMetapowerball5PicksterList(state, list) {
      state.metapowerball5PicksterList = list;
    },
    setMetapowerball3PicksterList(state, list) {
      state.metapowerball3PicksterList = list;
    },
    setCoinladder5PicksterList(state, list) {
      state.coinladder5PicksterList = list;
    },
    setCoinladder3PicksterList(state, list) {
      state.coinladder3PicksterList = list;
    },
    setMetaladder5PicksterList(state, list) {
      state.metaladder5PicksterList = list;
    },
    setMetaladder3PicksterList(state, list) {
      state.metaladder3PicksterList = list;
    },
    setDhpowerballPicksterList(state, list) {
      state.dhpowerballPicksterList = list;
    },
    setDhladderPicksterList(state, list) {
      state.dhladderPicksterList = list;
    },
    setPbgpowerballPicksterList(state, list) {
      state.pbgpowerballPicksterList = list;
    },
  },
  actions: {
    // 동행 픽 블락 시간
    getRecentTimeBlock({ commit }) {
      const now = new Date();
      const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
      const koreaTimeDiff = 9 * 60 * 60 * 1000;
      const today = new Date(utc + koreaTimeDiff);
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const recent = today.getTime();

      const time1 = new Date(`${year}-${month}-${day}`).getTime();
      const time2 = new Date(`${year}-${month}-${day}-06:00`).getTime();
      if (recent > time1 && recent < time2) {
        commit("setDhBlock", false);
      } else {
        commit("setDhBlock", true);
      }
    },
    // 픽 알람 데이터 수정
    pickAlarmDataEdit({ commit }, data) {
      commit("setPickAlarmData", data);
      setTimeout(() => commit("setRmAlarmData", data), 5000);
    },
    // 나의 EOS파워볼5분 픽 정보
    async updatemyEOSpowerball5PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        oe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("eospowerball5", model);

        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextEOSpowerball5Info.round;
          if (myPickRound == nextRound) {
            commit("setmyEOSpowerball5Pick", 1);
            commit("setmyEOSpowerball5PickInfo", res.data);
          } else {
            commit("setmyEOSpowerball5Pick", 0);
            commit("setmyEOSpowerball5PickInfo", pickModel);
          }
        } else {
          commit("setmyEOSpowerball5Pick", 0);
          commit("setmyEOSpowerball5PickInfo", pickModel);
        }
      }
    },
    // 나의 EOS파워볼3분 픽 정보
    async updatemyEOSpowerball3PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        oe: 0,
        pwOe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("eospowerball3", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextEOSpowerball3Info.round;
          if (myPickRound == nextRound) {
            commit("setmyEOSpowerball3Pick", 1);
            commit("setmyEOSpowerball3PickInfo", res.data);
          } else {
            commit("setmyEOSpowerball3Pick", 0);
            commit("setmyEOSpowerball3PickInfo", pickModel);
          }
        } else {
          commit("setmyEOSpowerball3Pick", 0);
          commit("setmyEOSpowerball3PickInfo", pickModel);
        }
      }
    },
    // 나의 코인파워볼5분 픽 정보
    async updatemyCoinpowerball5PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        oe: 0,
        pwOe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("coinpowerball5", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextCoinpowerball5Info.round;
          if (myPickRound == nextRound) {
            commit("setmyCoinpowerball5Pick", 1);
            commit("setmyCoinpowerball5PickInfo", res.data);
          } else {
            commit("setmyCoinpowerball5Pick", 0);
            commit("setmyCoinpowerball5PickInfo", pickModel);
          }
        } else {
          commit("setmyCoinpowerball5Pick", 0);
          commit("setmyCoinpowerball5PickInfo", pickModel);
        }
      }
    },
    // 나의 코인파워볼3분 픽 정보
    async updatemyCoinpowerball3PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        oe: 0,
        pwOe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("coinpowerball3", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextCoinpowerball3Info.round;
          if (myPickRound == nextRound) {
            commit("setmyCoinpowerball3Pick", 1);
            commit("setmyCoinpowerball3PickInfo", res.data);
          } else {
            commit("setmyCoinpowerball3Pick", 0);
            commit("setmyCoinpowerball3PickInfo", pickModel);
          }
        } else {
          commit("setmyCoinpowerball3Pick", 0);
          commit("setmyCoinpowerball3PickInfo", pickModel);
        }
      }
    },
    // 나의 메타파워볼5분 픽 정보
    async updatemyMetapowerball5PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        oe: 0,
        pwOe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("metapowerball5", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextMetapowerball5Info.round;
          if (myPickRound == nextRound) {
            commit("setmyMetapowerball5Pick", 1);
            commit("setmyMetapowerball5PickInfo", res.data);
          } else {
            commit("setmyMetapowerball5Pick", 0);
            commit("setmyMetapowerball5PickInfo", pickModel);
          }
        } else {
          commit("setmyMetapowerball5Pick", 0);
          commit("setmyMetapowerball5PickInfo", pickModel);
        }
      }
    },
    // 나의 메타파워볼3분 픽 정보
    async updatemyMetapowerball3PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        oe: 0,
        pwOe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("metapowerball3", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextMetapowerball3Info.round;
          if (myPickRound == nextRound) {
            commit("setmyMetapowerball3Pick", 1);
            commit("setmyMetapowerball3PickInfo", res.data);
          } else {
            commit("setmyMetapowerball3Pick", 0);
            commit("setmyMetapowerball3PickInfo", pickModel);
          }
        } else {
          commit("setmyMetapowerball3Pick", 0);
          commit("setmyMetapowerball3PickInfo", pickModel);
        }
      }
    },
    // 나의 코인사다리5분 픽 정보
    async updatemyCoinladder5PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        sub: 0,
        lr: 0,
        oe: 0,
        line: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("coinladder5", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextCoinladder5Info.round;
          if (myPickRound == nextRound) {
            commit("setmyCoinladder5Pick", 1);
            commit("setmyCoinladder5PickInfo", res.data);
          } else {
            commit("setmyCoinladder5Pick", 0);
            commit("setmyCoinladder5PickInfo", pickModel);
          }
        } else {
          commit("setmyCoinladder5Pick", 0);
          commit("setmyCoinladder5PickInfo", pickModel);
        }
      }
    },
    // 나의 코인사다리3분 픽 정보
    async updatemyCoinladder3PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        sub: 0,
        lr: 0,
        oe: 0,
        line: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("coinladder3", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextCoinladder3Info.round;
          if (myPickRound == nextRound) {
            commit("setmyCoinladder3Pick", 1);
            commit("setmyCoinladder3PickInfo", res.data);
          } else {
            commit("setmyCoinladder3Pick", 0);
            commit("setmyCoinladder3PickInfo", pickModel);
          }
        } else {
          commit("setmyCoinladder3Pick", 0);
          commit("setmyCoinladder3PickInfo", pickModel);
        }
      }
    },
    // 나의 메타사다리5분 픽 정보
    async updatemyMetaladder5PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        sub: 0,
        lr: 0,
        oe: 0,
        line: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("metaladder5", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextMetaladder5Info.round;
          if (myPickRound == nextRound) {
            commit("setmyMetaladder5Pick", 1);
            commit("setmyMetaladder5PickInfo", res.data);
          } else {
            commit("setmyMetaladder5Pick", 0);
            commit("setmyMetaladder5PickInfo", pickModel);
          }
        } else {
          commit("setmyMetaladder5Pick", 0);
          commit("setmyMetaladder5PickInfo", pickModel);
        }
      }
    },
    // 나의 메타사다리3분 픽 정보
    async updatemyMetaladder3PickInfo({ commit, state }, userIdx) {
      const pickModel = {
        sub: 0,
        lr: 0,
        oe: 0,
        line: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("metaladder3", model);
        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextMetaladder3Info.round;
          if (myPickRound == nextRound) {
            commit("setmyMetaladder3Pick", 1);
            commit("setmyMetaladder3PickInfo", res.data);
          } else {
            commit("setmyMetaladder3Pick", 0);
            commit("setmyMetaladder3PickInfo", pickModel);
          }
        } else {
          commit("setmyMetaladder3Pick", 0);
          commit("setmyMetaladder3PickInfo", pickModel);
        }
      }
    },
    // 나의 동행파워볼 픽 정보
    async updatemyDhpowerballPickInfo({ commit, state }, userIdx) {
      const pickModel = {
        oe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("dhpowerball", model);

        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextDhpowerballInfo.round;
          if (myPickRound == nextRound) {
            commit("setmyDhpowerballPick", 1);
            commit("setmyDhpowerballPickInfo", res.data);
          } else {
            commit("setmyDhpowerballPick", 0);
            commit("setmyDhpowerballPickInfo", pickModel);
          }
        } else {
          commit("setmyDhpowerballPick", 0);
          commit("setmyDhpowerballPickInfo", pickModel);
        }
      }
    },
    // 나의 동행사다리 픽 정보
    async updatemyDhladderPickInfo({ commit, state }, userIdx) {
      const pickModel = {
        sub: 0,
        lr: 0,
        oe: 0,
        line: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("dhladder", model);

        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextDhladderInfo.round;
          if (myPickRound == nextRound) {
            commit("setmyDhladderPick", 1);
            commit("setmyDhladderPickInfo", res.data);
          } else {
            commit("setmyDhladderPick", 0);
            commit("setmyDhladderPickInfo", pickModel);
          }
        } else {
          commit("setmyDhladderPick", 0);
          commit("setmyDhladderPickInfo", pickModel);
        }
      }
    },
    // 나의 PBG파워볼 픽 정보
    async updatemyPbgpowerballPickInfo({ commit, state }, userIdx) {
      const pickModel = {
        oe: 0,
        pwUnover: 0,
        size: 0,
        unover: 0,
      };
      if (userIdx) {
        const model = { userIdx: userIdx };
        const res = await apiGamePick("pbgpowerball", model);

        if (res.data) {
          let myPickRound = res.data.round;
          let nextRound = state.nextPbgpowerballInfo.round;
          if (myPickRound == nextRound) {
            commit("setmyPbgpowerballPick", 1);
            commit("setmyPbgpowerballPickInfo", res.data);
          } else {
            commit("setmyPbgpowerballPick", 0);
            commit("setmyPbgpowerballPickInfo", pickModel);
          }
        } else {
          commit("setmyPbgpowerballPick", 0);
          commit("setmyPbgpowerballPickInfo", pickModel);
        }
      }
    },
    // 보석 선물 유저 저장
    joinGiftUserInfo({ commit }, userInfo) {
      commit("setGiftUserInfo", userInfo);
    },
    // 방정보  저장
    joinChatRoom({ commit }, roomInfo) {
      commit("setRoomInfo", roomInfo);
    },
    // 유저 정보 저장
    setUserInfo({ commit }, userData) {
      if (userData) {
        commit("setStoreUserInfo", userData);
        commit("setloginState", !!userData);
      }
    },
    // 현재 페이지
    setCurrentPage({ commit }, pageName) {
      if (pageName) {
        commit("setPageState", pageName);
      }
    },
    // 알람 가져오기
    async getNewAlarm({ commit }) {
      const res = await apiAlarm();
      if (res) {
        commit("setAlarm", res.data);
      }
    },
    // EOS파워볼5분 다음 게임 정보
    async updateNextEOSpowerball5Info({ commit }) {
      const res = await apiNextInfo("eospowerball5");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextEOSpowerball5Info", info);
      }
    },
    // EOS파워볼3분 다음 게임 정보
    async updateNextEOSpowerball3Info({ commit }) {
      const res = await apiNextInfo("eospowerball3");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextEOSpowerball3Info", info);
      }
    },
    // 코인파워볼5분 다음 게임 정보
    async updateNextcoinpowerball5Info({ commit }) {
      const res = await apiNextInfo("coinpowerball5");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextCoinpowerball5Info", info);
      }
    },
    // 코인파워볼3분 다음 게임 정보
    async updateNextcoinpowerball3Info({ commit }) {
      const res = await apiNextInfo("coinpowerball3");

      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextCoinpowerball3Info", info);
      }
    },
    // 메타파워볼5분 다음 게임 정보
    async updateNextmetapowerball5Info({ commit }) {
      const res = await apiNextInfo("metapowerball5");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextMetapowerball5Info", info);
      }
    },
    // 메타파워볼3분 다음 게임 정보
    async updateNextmetapowerball3Info({ commit }) {
      const res = await apiNextInfo("metapowerball3");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextMetapowerball3Info", info);
      }
    },
    // 코인사다리5분 다음 게임 정보
    async updateNextcoinladder5Info({ commit }) {
      const res = await apiNextInfo("coinladder5");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextCoinladder5Info", info);
      }
    },
    // 코인사다리3분 다음 게임 정보
    async updateNextcoinladder3Info({ commit }) {
      const res = await apiNextInfo("coinladder3");

      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextCoinladder3Info", info);
      }
    },
    // 메타사다리5분 다음 게임 정보
    async updateNextmetaladder5Info({ commit }) {
      const res = await apiNextInfo("metaladder5");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextMetaladder5Info", info);
      }
    },
    // 메타사다리3분 다음 게임 정보
    async updateNextmetaladder3Info({ commit }) {
      const res = await apiNextInfo("metaladder3");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextMetaladder3Info", info);
      }
    },
    // 동행파워볼 다음 게임 정보
    async updateNextdhpowerballInfo({ commit }) {
      const res = await apiNextInfo("dhpowerball");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextDhpowerballInfo", info);
      }
    },
    // 동행사다리 다음 게임 정보
    async updateNextdhladderInfo({ commit }) {
      const res = await apiNextInfo("dhladder");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextDhladderInfo", info);
      }
    },
    // PBG파워볼 다음 게임 정보
    async updateNextpbgpowerballInfo({ commit }) {
      const res = await apiNextInfo("pbgpowerball");
      if (res) {
        let time = res.nextGame.StartTime;
        const contime = new Date(time);
        const info = {
          time: contime,
          round: res.nextGame.round,
        };
        commit("setNextPbgpowerballInfo", info);
      }
    },
    // EOS파워볼5분 남은 시간 계산
    calcEOSpower5DiffTime({ commit, state }) {
      if (!state.nextEOSpowerball5Info.time) {
        commit("updateEOSpower5Diff", state.EOSpowerball5diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextEOSpowerball5Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updateEOSpower5Diff", diffTime);
      }
    },
    // EOS파워볼3분 남은 시간 계산
    calcEOSpower3DiffTime({ commit, state }) {
      if (!state.nextEOSpowerball3Info.time) {
        commit("updateEOSpower3Diff", state.EOSpowerball3diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextEOSpowerball3Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updateEOSpower3Diff", diffTime);
      }
    },
    // 코인파워볼5분 남은 시간 계산
    calcCoin5DiffTime({ commit, state }) {
      if (!state.nextCoinpowerball5Info.time) {
        commit("updatecoin5Diff", state.coinpowerball5diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextCoinpowerball5Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatecoin5Diff", diffTime);
      }
    },
    // 코인파워볼3분 남은 시간 계산
    calcCoin3DiffTime({ commit, state }) {
      if (!state.nextCoinpowerball3Info.time) {
        commit("updatecoin3Diff", state.coinpowerball3diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextCoinpowerball3Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatecoin3Diff", diffTime);
      }
    },
    // 메타파워볼5분 남은 시간 계산
    calcMeta5DiffTime({ commit, state }) {
      if (!state.nextMetapowerball5Info.time) {
        commit("updatemeta5Diff", state.metapowerball5diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextMetapowerball5Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatemeta5Diff", diffTime);
      }
    },
    // 메타파워볼3분 남은 시간 계산
    calcMeta3DiffTime({ commit, state }) {
      if (!state.nextMetapowerball3Info.time) {
        commit("updatemeta3Diff", state.metapowerball3diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextMetapowerball3Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatemeta3Diff", diffTime);
      }
    },
    // 코인사다리5븐 남은 시간 계산
    calcCoinladder5DiffTime({ commit, state }) {
      if (!state.nextCoinladder5Info.time) {
        commit("updatecoinladder5Diff", state.coinladder5diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextCoinladder5Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatecoinladder5Diff", diffTime);
      }
    },
    // 코인사다리3븐 남은 시간 계산
    calcCoinladder3DiffTime({ commit, state }) {
      if (!state.nextCoinladder3Info.time) {
        commit("updatecoinladder3Diff", state.coinladder3diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextCoinladder3Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatecoinladder3Diff", diffTime);
      }
    },
    // 메타사다리5븐 남은 시간 계산
    calcMetaladder5DiffTime({ commit, state }) {
      if (!state.nextMetaladder5Info.time) {
        commit("updatemetaladder5Diff", state.metaladder5diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextMetaladder5Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatemetaladder5Diff", diffTime);
      }
    },
    // 메타사다리3븐 남은 시간 계산
    calcMetaladder3DiffTime({ commit, state }) {
      if (!state.nextMetaladder3Info.time) {
        commit("updatemetaladder3Diff", state.metaladder3diffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextMetaladder3Info.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatemetaladder3Diff", diffTime);
      }
    },
    // 동행파워볼 남은 시간 계산
    calcDhpowerballDiffTime({ commit, state }) {
      if (!state.nextDhpowerballInfo.time) {
        commit("updatedhpowerballDiff", state.dhpowerballdiffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextDhpowerballInfo.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatedhpowerballDiff", diffTime);
      }
    },
    // 동행사다리 남은 시간 계산
    calcDhladderDiffTime({ commit, state }) {
      if (!state.nextDhladderInfo.time) {
        commit("updatedhladderDiff", state.dhladderdiffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextDhladderInfo.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatedhladderDiff", diffTime);
      }
    },
    // PBG파워볼 남은 시간 계산
    calcPbgpowerballDiffTime({ commit, state }) {
      if (!state.nextPbgpowerballInfo.time) {
        commit("updatepbgpowerballDiff", state.pbgpowerballdiffTime - 1);
      } else {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
        const koreaTimeDiff = 9 * 60 * 60 * 1000;
        const today = new Date(utc + koreaTimeDiff);
        const diffNumber = state.nextPbgpowerballInfo.time - today;
        const diffDay = new Date(diffNumber);
        const diffTime = (diffDay.getTime() / 1000).toFixed(0);
        commit("updatepbgpowerballDiff", diffTime);
      }
    },
    // EOS파워볼5분 픽 타임 오버 설정
    setPickEOSpower5TimeOver({ commit }, status) {
      commit("updateEOSpower5TimeOver", status);
    },
    // EOS파워볼3분 픽 타임 오버 설정
    setPickEOSpower3TimeOver({ commit }, status) {
      commit("updateEOSpower3TimeOver", status);
    },
    // 코인파워볼5분 픽 타임 오버 설정
    setPickCoin5TimeOver({ commit }, status) {
      commit("updatecoin5TimeOver", status);
    },
    // 코인파워볼3분 픽 타임 오버 설정
    setPickCoin3TimeOver({ commit }, status) {
      commit("updatecoin3TimeOver", status);
    },
    // 메타파워볼5분 픽 타임 오버 설정
    setPickMeta5TimeOver({ commit }, status) {
      commit("updatemeta5TimeOver", status);
    },
    // 메타파워볼3분 픽 타임 오버 설정
    setPickMeta3TimeOver({ commit }, status) {
      commit("updatemeta3TimeOver", status);
    },
    // 코인사다리5분 픽 타임 오버 설정
    setPickCoinladder5TimeOver({ commit }, status) {
      commit("updatecoinladder5TimeOver", status);
    },
    // 코인사다리3분 픽 타임 오버 설정
    setPickCoinladder3TimeOver({ commit }, status) {
      commit("updatecoinladder3TimeOver", status);
    },
    // 메타사다리5분 픽 타임 오버 설정
    setPickMetaladder5TimeOver({ commit }, status) {
      commit("updatemetaladder5TimeOver", status);
    },
    // 메타사다리3분 픽 타임 오버 설정
    setPickMetaladder3TimeOver({ commit }, status) {
      commit("updatemetaladder3TimeOver", status);
    },
    // 동행파워볼 픽 타임 오버 설정
    setPickDhpowerballTimeOver({ commit }, status) {
      commit("updatedhpowerballTimeOver", status);
    },
    // 동행사다리 픽 타임 오버 설정
    setPickDhladderTimeOver({ commit }, status) {
      commit("updatedhladderTimeOver", status);
    },
    // PBG파워볼 픽 타임 오버 설정
    setPickPbgpowerballTimeOver({ commit }, status) {
      commit("updatepbgpowerballTimeOver", status);
    },
    // 방 체크를 위한 방 정보 저장
    settingsetCheckRoomInfo({ commit }, status) {
      commit("setCheckRoomInfo", status);
    },
    // 실시간 가입 회원 수 확인 (소켓 전송 용도)
    settingSetSignUpCount({ commit }, status) {
      commit("setSignUpCount", status + 1);
    },
    // EOS파워볼5분 실시간 게임 결과 정보
    async getEOSpowerball5RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "eospowerball5", model);

      if (res) {
        const EOSpowerball5LiveList = res.list;
        commit("setEOSpowerball5Round", EOSpowerball5LiveList[0].round);
        commit("setEOSpowerball5LiveList", EOSpowerball5LiveList);
      }
    },
    // EOS파워볼3분 실시간 게임 결과 정보
    async getEOSpowerball3RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "eospowerball3", model);
      if (res) {
        const EOSpowerball3LiveList = res.list;
        commit("setEOSpowerball3Round", EOSpowerball3LiveList[0].round);
        commit("setEOSpowerball3LiveList", EOSpowerball3LiveList);
      }
    },
    // 코인파워볼5분 실시간 게임 결과 정보
    async getCoinpowerball5RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "coinpowerball5", model);

      if (res) {
        const coinpowerball5LiveList = res.list;
        commit("setcoinpowerball5Round", coinpowerball5LiveList[0].round);
        commit("setCoinpowerball5LiveList", coinpowerball5LiveList);
      }
    },
    // 코인파워볼3분 실시간 게임 결과 정보
    async getCoinpowerball3RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "coinpowerball3", model);

      if (res) {
        const coinpowerball3LiveList = res.list;
        commit("setcoinpowerball3Round", coinpowerball3LiveList[0].round);
        commit("setCoinpowerball3LiveList", coinpowerball3LiveList);
      }
    },
    // 메타파워볼5분 실시간 게임 결과 정보
    async getMetapowerball5RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "metapowerball5", model);

      if (res) {
        const metapowerball5LiveList = res.list;
        commit("setmetapowerball5Round", metapowerball5LiveList[0].round);
        commit("setMetapowerball5LiveList", metapowerball5LiveList);
      }
    },
    // 메타파워볼3분 실시간 게임 결과 정보
    async getMetapowerball3RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "metapowerball3", model);
      if (res) {
        const metapowerball3LiveList = res.list;
        commit("setmetapowerball3Round", metapowerball3LiveList[0].round);
        commit("setMetapowerball3LiveList", metapowerball3LiveList);
      }
    },
    // 코인사다리5분 실시간 게임 결과 정보
    async getCoinladder5RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "coinladder5", model);

      if (res) {
        const coinladder5LiveList = res.list;
        commit("setcoinladder5Round", coinladder5LiveList[0].round);
        commit("setCoinladder5LiveList", coinladder5LiveList);
      }
    },
    // 코인사다리3분 실시간 게임 결과 정보
    async getCoinladder3RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "coinladder3", model);

      if (res) {
        const coinladder3LiveList = res.list;
        commit("setcoinladder3Round", coinladder3LiveList[0].round);
        commit("setCoinladder3LiveList", coinladder3LiveList);
      }
    },
    // 메타사다리5분 실시간 게임 결과 정보
    async getMetaladder5RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "metaladder5", model);

      if (res) {
        const metaladder5LiveList = res.list;
        commit("setmetaladder5Round", metaladder5LiveList[0].round);
        commit("setMetaladder5LiveList", metaladder5LiveList);
      }
    },
    // 메타사다리3분 실시간 게임 결과 정보
    async getMetaladder3RoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "metaladder3", model);
      if (res) {
        const metaladder3LiveList = res.list;
        commit("setmetaladder3Round", metaladder3LiveList[0].round);
        commit("setMetaladder3LiveList", metaladder3LiveList);
      }
    },
    // 동행피워볼 실시간 게임 결과 정보
    async getDhpowerballRoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "dhpowerball", model);
      if (res) {
        const dhpowerballLiveList = res.list;
        commit("setdhpowerballRound", dhpowerballLiveList[0].round);
        commit("setDhpowerballLiveList", dhpowerballLiveList);
      }
    },
    // 동행사다리 실시간 게임 결과 정보
    async getDhladderRoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "dhladder", model);
      if (res) {
        const dhladderLiveList = res.list;
        commit("setdhladderRound", dhladderLiveList[0].round);
        commit("setDhladderLiveList", dhladderLiveList);
      }
    },
    // PBG파워볼 실시간 게임 결과 정보
    async getPbgpowerballRoundList({ commit }) {
      const method = "/round/list";
      const model = {
        rowCnt: 10,
        page: 1,
      };
      const res = await apiGameLiveInfo(method, "pbgpowerball", model);
      if (res) {
        const pbgpowerballLiveList = res.list;
        commit("setpbgpowerballRound", pbgpowerballLiveList[0].round);
        commit("setPbgpowerballLiveList", pbgpowerballLiveList);
      }
    },
    // 나를 구독하는 회원
    async getfanList({ commit }) {
      const req = { page: 1, rowCnt: 1000 };
      const res = await apiFanList(req);
      if (res) {
        for (const key in res.list) {
          res.list[key].isItemTrue = false;
        }
        const list = res.list;
        commit("setFanList", list);
      }
    },
    // 내가 구독하는 회원
    async getcelebList({ commit }) {
      const req = { page: 1, rowCnt: 1000 };
      const res = await apiCelebList(req);
      if (res) {
        for (const key in res.list) {
          res.list[key].isItemTrue = false;
        }
        const list = res.list;
        commit("setCelebList", list);
      }
    },
    // 내가 차단한 회원
    async getblockList({ commit }) {
      const req = { page: 1, rowCnt: 1000 };
      const res = await apiUserBlockingList(req);
      if (res) {
        for (const key in res.list) {
          res.list[key].isItemTrue = false;
        }
        const list = res.list;
        commit("setBlockList", list);
      }
    },
    // Qna 등록 시, 소켓으로 전송
    AddQna() {
      const sendModel = { mode: "user_question_cnt", type: "q" };
      this.socket.emit("chat", sendModel);
    },
    // 캐시 충전 요청시, 소켓으로 전송
    setCashCharge() {
      const sendModel = { mode: "user_cashcharge_cnt" };
      this.socket.emit("chat", sendModel);
    },
    // 로그인 후, 소켓으로 로그인 정보 전송
    ioAuth({ state }) {
      const userId = state.userInfo.lgnId;
      const authModel = { mode: "io_auth", mb_id: userId };
      this.socket.emit("chat", authModel);
    },
    // 메인 소켓 전송
    MainSocketConnect({ state, commit, dispatch }) {
      const systemMessageConvertor = (item) => {
        let code;
        let data;
        if (item.items) {
          code = item.items.msg;
          switch (code) {
            // 1:1 채팅 신청
            case "C16": {
              data = item.items.val;
              if (data.toUserIdx == state.userInfo.userIdx) {
                commit("setTalkRoomData", data);
              }
              break;
            }
            // 픽 알림 정보
            case "C17": {
              data = item.items.val;
              dispatch("pickAlarmDataEdit", data);
            }
          }
        }
      };
      // 메인 소켓 연결 정보
      const join = () => {
        const joinModel = {
          mode: "room_join",
          room_no: "619d061113f84a2786e8c3418843a144",
        };
        this.socket.emit("chat", joinModel);
      };
      // 게임 픽 분포도 정보 받아오기 연결
      const pickRatioJoin = () => {
        const sendModel = { mode: "pick_ratio_join" };
        this.socket.emit("chat", sendModel);
      };
      // 소켓 연결
      const connect = () => {
        this.socket = io(SOCKET_INFO);
        this.socket.on("connect", (res) => {
          pickRatioJoin();
          join();
        });
        this.socket.on("disconnect", (res) => {
          if (this.socket.disconnected) {
            // console.log("disconnect");
          }
        });
        this.socket.on("reconnect", (res) => {
          // console.log("reconnect success");
        });
        // chat으로 데이터 받아오기
        this.socket.on("chat", async (msg) => {
          const type = msg.m;
          switch (type) {
            // 해당 타입으로 넘어오면 해당 메소드 호출
            case "msg_new":
              if (msg) {
                systemMessageConvertor(msg);
              }
              break;
          }
        });
        // 실시간 픽 분포도
        this.socket.on("game", (res) => {
          let pickRatio = res;
          for (const item in pickRatio) {
            for (const key in pickRatio[item]) {
              pickRatio[item][key] = Number(pickRatio[item][key]).toFixed(0);
            }
          }
          // 새로운 픽 분포도 올때마다 업데이트
          // 픽 분포도 정보 업데이트
          commit("setGamePickRatio", pickRatio);
        });
        this.socket.on("err", () => {
          console.error("chat err");
        });
      };
      connect();
    },
    // 동행 파워볼 베스트 채팅방 목록
    async getBestChatRoomList({ commit }) {
      const model = {
        page: 1,
        rowCnt: 10,
        gameType: "DH",
      };
      try {
        const res = await apiRoomList(model);
        if (res) {
          const list = res.list;
          for (const item of list) {
            const totalRecord = item.mainTotLose + item.mainTotWin;
            item.totalRecord = totalRecord;
            if (totalRecord <= 0) {
              item.winRate = 0;
            } else {
              item.winRate = (item.mainTotWin / totalRecord).toFixed(2) * 100;
            }
            //conWin=1, conLose=0
            item.wlStatusType = !item.mainConLose ? 1 : 0;
            item.wlStatus = !item.mainConLose
              ? item.mainConWin
              : item.mainConLose;
          }
          commit("setRoomList", list);
        }
      } catch (e) {
        // console.error(e);
      }
    },
    // 채팅 비공개방 비밀번호 확인
    async roomImmigration({ commit }, item) {
      const req = {
        roomIdx: item.roomIdx,
        roomsPw: item.roomPw ? item.roomPw : "",
        managerIdx: item.userIdx || item.managerIdx,
      };

      const res = await apiRoomImmigration(req);
      if (res) {
        return true;
      } else {
        return false;
      }
    },
    // 본인 개설 채팅방 여부 확인
    async chatOwn({ commit }) {
      const res = await apiRoomMine();
      if (res.roomIdx) {
        const myroom = {
          roomIdx: res.roomIdx,
          gameType: res.gameType,
        };
        commit("setMyRoom", myroom);
      } else {
        commit("setMyRoom", 0);
      }
    },

    // 픽스터리스트 불러오기
    async getPicksterList({ commit }, type) {
      let flagType;
      if (type.indexOf("ladder") > -1) {
        flagType = "NW";
      } else {
        flagType = "PW";
      }
      const model = {
        flag: flagType,
      };
      const res = await apiGamePickster(model, type);
      if (res) {
        let list = res.list;
        let length = list.length;
        if (length > 3) {
          list = list.slice(0, 3);
        }
        switch (type) {
          case "eospowerball5":
            commit("setEOSpowerball5PicksterList", list);
            break;
          case "eospowerball3":
            commit("setEOSpowerball3PicksterList", list);
            break;
          case "coinpowerball5":
            commit("setCoinpowerball5PicksterList", list);
            break;
          case "coinpowerball3":
            commit("setCoinpowerball3PicksterList", list);
            break;
          case "metapowerball5":
            commit("setMetapowerball5PicksterList", list);
            break;
          case "metapowerball3":
            commit("setMetapowerball3PicksterList", list);
            break;
          case "coinladder5":
            commit("setCoinladder5PicksterList", list);
            break;
          case "coinladder3":
            commit("setCoinladder3PicksterList", list);
            break;
          case "metaladder5":
            commit("setMetaladder5PicksterList", list);
            break;
          case "metaladder3":
            commit("setMetaladder3PicksterList", list);
            break;
          case "dhpowerball":
            commit("setDhpowerballPicksterList", list);
            break;
          case "dhladder":
            commit("setDhladderPicksterList", list);
            break;
          case "pbgpowerball":
            commit("setPbgpowerballPicksterList", list);
            break;
        }
      }
    },
  },
  strict: true,
});
